const articles_quoted = [
  {
    mediaName: "UnHerd",
    mediaURL: "https://unherd.com/2018/09/save-democracy-must-disrupt/",
    articleTitle: "To save democracy, we must disrupt it",
    date: "03 September 2018",
  },
  {
    mediaName: "MIT Technology Review",
    mediaURL:
      "https://www.technologyreview.com/s/611816/the-simple-but-ingenious-system-taiwan-uses-to-crowdsource-its-laws/",
    articleTitle:
      "The simple but ingenious system Taiwan uses to crowdsource its laws",
    date: "21 August 2018",
  },
  {
    mediaName: "Wired",
    mediaURL:
      "https://www.wired.co.uk/article/taiwan-sunflower-revolution-audrey-tang-g0v",
    articleTitle: "Taiwan’s revolutionary hackers are forking the government",
    date: "4 May 2018",
  },
  {
    mediaName: "Mashable",
    mediaURL:
      "https://mashable.com/2018/01/19/womens-march-march-on-crowdsource-poll-activism/",
    articleTitle:
      "Women's March anniversary sets the stage for new political campaign with a tech twist",
    date: "19 January 2018",
  },
  {
    mediaName: "MIT Technology Review",
    mediaURL:
      "https://www.technologyreview.com/s/607990/the-internet-doesnt-have-to-be-bad-for-democracy/",
    articleTitle: "The Internet Doesn’t Have to Be Bad for Democracy",
    date: "2 June 2017",
  },
  {
    mediaName: "Civicist",
    mediaURL: "https://civichall.org/civicist/vtaiwan-democracy-frontier/",
    articleTitle:
      "VTAIWAN: PUBLIC PARTICIPATION METHODS ON THE CYBERPUNK FRONTIER OF DEMOCRACY",
    date: "11 August 2016",
  },
  {
    mediaName: "Daum (Korean)",
    mediaURL: "https://news.v.daum.net/v/20160302145809114",
    articleTitle: "Changing the relationship between citizens and government",
    date: "2 March 2016",
  },
  {
    mediaName: "Mobilization Lab",
    mediaURL:
      "https://mobilisationlab.org/blooming-digital-democracy-taiwan-sunflower-movement/#.Vur9oxIrLUI",
    articleTitle: "Blooming digital democracy in Taiwan’s Sunflower movement",
    date: "25 January 2016",
  },
  {
    mediaName: "Coral Project",
    mediaURL: "https://coralproject.net/blog/new-community-tools-polis/",
    articleTitle: "New Community Tools: Polis",
    date: "9 December 2015",
  },
  {
    mediaName: "GeekWire",
    mediaURL: "https://www.geekwire.com/2014/startup-spotlight-polis/",
    articleTitle:
      "Startup Spotlight: Pol.is uses machine learning, data visualization to help large groups spur conversation",
    date: "17 April 2014",
  },
]

export default articles_quoted
