/** @jsx jsx */

import { jsx } from "theme-ui"

const GatesTweet = () => {
  return (
    <blockquote class="twitter-tweet tw-align-center">
      <p lang="en" dir="ltr">
        : We are studying new viral mutations in my lab that would never be on
        our radar without{" "}
        <a href="https://twitter.com/trvrb?ref_src=twsrc%5Etfw">@trvrb</a> and{" "}
        <a href="https://twitter.com/richardneher?ref_src=twsrc%5Etfw">
          @richardneher
        </a>{" "}
        Nextstrain
      </p>
      &mdash; Hensley Lab (@SCOTTeHENSLEY){" "}
      <a href="https://twitter.com/SCOTTeHENSLEY/status/841769108001718273?ref_src=twsrc%5Etfw">
        March 14, 2017
      </a>
    </blockquote>
  )
}

export default GatesTweet
