import React from "react"

import { Box, Themed, Heading, Link, Text } from "theme-ui"
import talks from "../data/talks"

/*

city: "Quebec City, Canada"
conferenceName: "Web à Québec"
conferenceUrl: "https://webaquebec.org/programmation/ai-the-future-of-the-political-party"
date: "2019 April"
deckUrl: "https://docs.google.com/presentation/d/1tvkkxf6gk8JT0oT_2OSJhALxforIIY-P3Ci2YZaVYp0/edit?usp=sharing"
title: "AI and the future of the political party"
videoUrl: null

*/

const Talk = props => {
  const {
    title,
    conferenceUrl,
    conferenceName,
    city,
    date,
    deckUrl,
    videoUrl,
  } = props.talk
  return (
    <Themed.p>
      {/* {title && <br />} */}
      <Text sx={{ fontWeight: 700 }}>{city}</Text>, {date},{" "}
      {conferenceUrl ? (
        <Link href={conferenceUrl}>{conferenceName}</Link>
      ) : (
        <Text>{conferenceName}</Text>
      )}{" "}
      {title && <Text sx={{ fontStyle: "italic" }}>{title}</Text>}{" "}
      {deckUrl && <Link href={deckUrl}>Deck</Link>}{" "}
      {videoUrl && <Link href={videoUrl}>Video</Link>}
    </Themed.p>
  )
}

const Talks = () => (
  <Box>
    <Box>
      {talks.map(talk => {
        return (
          <Talk key={`${talk.title}_${talk.date}_${talk.city}`} talk={talk} />
        )
      })}
    </Box>
  </Box>
)

export default Talks
