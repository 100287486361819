const books_quoted = [
  {
    bookName: "The Death of the Gods: The New Global Power Grab",
    amazonURL: "https://www.amazon.com/WTF-Whats-Future-Why-Its/dp/0062565710",
    isbn13: "978-1785151330",
    authorName: "Carl Miller",
    publicationDate: "23 August 2018",
  },
  {
    bookName: "WTF?: What's the Future and Why It's Up to Us",
    amazonURL: "https://www.amazon.com/WTF-Whats-Future-Why-Its/dp/0062565710",
    isbn13: "978-0062565716",
    authorName: "Tim O'Reilly",
    publicationDate: "10 October 2017",
  },
]

export default books_quoted
