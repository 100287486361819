import React from "react"
import { Box, Themed, Heading, Text, Link } from "theme-ui"
import articles_quoted from "../data/articles_quoted"
import books_quoted from "../data/books_quoted"

const Book = props => {
  const {
    bookName,
    amazonURL,
    isbn13,
    authorName,
    publicationDate,
  } = props.book
  return (
    <Themed.p>
      <Link href={amazonURL}>
        <Text sx={{ fontStyle: "italic" }}>{bookName}</Text>
      </Link>{" "}
      by {authorName}. {publicationDate} ISBN13—{isbn13}
    </Themed.p>
  )
}

const Article = props => {
  const { mediaName, mediaURL, articleTitle, date } = props.article
  return (
    <Themed.p>
      <Link sx={{ fontStyle: "italic" }} href={mediaURL}>
        {articleTitle}
      </Link>
      <br />
      <Text sx={{ fontWeight: 700 }}>{mediaName}</Text> {date}
    </Themed.p>
  )
}

const Quotes = () => (
  <Box sx={{ my: 5 }}>
    <Box sx={{ py: [4] }}>
      {books_quoted.map(book => (
        <Book book={book} />
      ))}
    </Box>
    {articles_quoted.map(article => (
      <Article article={article} />
    ))}
  </Box>
)

export default Quotes
