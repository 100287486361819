/** @jsx jsx */
import { useStaticQuery, graphql } from "gatsby"
import { jsx, Box } from "theme-ui"
import Img from "gatsby-image"

const Cellxgene_section = () => {
  const data = useStaticQuery(graphql`
    query cellxgeneMap {
      file(relativePath: { eq: "cellxgene_human_development.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Box sx={{ my: 5 }}>
      <Img
        sx={{ my: 4, maxWidth: "40em" }}
        fluid={data.file.childImageSharp.fluid}
      />
    </Box>
  )
}

export default Cellxgene_section
