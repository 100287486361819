/*

{
  conferenceName: ,
  conferenceUrl: ,
  videoUrl: null,
  deckUrl: null,
  city: ,
  title: ,
  date: ,
},

*/

const talks = [
  {
    conferenceName:
      "Internal seminar, MIT Media Lab Center for Constructive Communication",
    conferenceUrl: "ccc.mit.edu",
    videoUrl: "null",
    deckUrl: null,
    city: "Boston, USA (remotely)",
    title:
      "Polis: Scaling deliberation by mapping high dimensional opinion spaces (pre-publication paper talk)",
    date: "2021 May",
  },
  {
    conferenceName: "Center for Collective Learning Seminar",
    conferenceUrl: "https://centerforcollectivelearning.org/seminar",
    videoUrl: "https://www.youtube.com/channel/UCAt9Qshzl9wzVFmAEQSOT9Q",
    deckUrl: null,
    city: "Toulouse, France (remotely)",
    title:
      "Polis: Scaling deliberation by mapping high dimensional opinion spaces (pre-publication paper talk)",
    date: "2021 April",
  },
  {
    conferenceName:
      "Internal seminar, Future of Humanity Institute Centre for the Governance of AI, Oxford University",
    conferenceUrl: "https://www.fhi.ox.ac.uk/",
    videoUrl: null,
    deckUrl: null,
    city: "Oxford, UK (remotely)",
    title:
      "Polis: Scaling deliberation by mapping high dimensional opinion spaces (pre-publication paper talk)",
    date: "2021 April",
  },
  {
    conferenceName: "International Forum on Digital Democracy",
    conferenceUrl: "https://ifdad.org/edition-2020/",
    videoUrl: null,
    deckUrl: null,
    city: "Venice, Italy (remotely)",
    title: "A CASE STUDY ON COMPUTER-AIDED POLICYMAKING",
    date: "2020 December",
  },
  {
    conferenceName: "g0v Summit 2020",
    conferenceUrl: "https://summit.g0v.tw/2020/",
    videoUrl: "https://www.youtube.com/watch?v=9ji81N_gmTw",
    deckUrl: null,
    city: "Taipei, Taiwan (remotely)",
    title: "POLITICAL MACHINES",
    date: "2020 December",
  },
   {
    conferenceName: "metagov seminar",
    conferenceUrl: "https://metagov.org/seminar/",
    videoUrl: "https://archive.org/details/metagov-8-12-pol.is",
    deckUrl: null,
    city: "San Francisco (remotely)",
    title: "AI and the future of the political party",
    date: "2020 August",
  },
  {
    conferenceName: "Web à Québec",
    conferenceUrl:
      "https://webaquebec.org/programmation/ai-the-future-of-the-political-party",
    videoUrl: null,
    deckUrl:
      "https://docs.google.com/presentation/d/1tvkkxf6gk8JT0oT_2OSJhALxforIIY-P3Ci2YZaVYp0/edit?usp=sharing",
    city: "Quebec City, Canada",
    title: "AI and the future of the political party",
    date: "2019 April",
  },
  {
    conferenceName: "Center for Advanced Study in the Behavioral Sciences",
    conferenceUrl: "",
    videoUrl: null,
    deckUrl: null,
    city: "Stanford University, California, USA",
    title: "Machine Learning and Democracy",
    date: "2019 March",
  },
  {
    conferenceName:
      "Netherlands Institue for Multiparty Democracy Annual Conference",
    conferenceUrl:
      "https://nimd.org/cutting-edge-technology-and-future-proof-politics-inndem2018/",
    videoUrl: null,
    deckUrl: null,
    city: "Amsterdam, Netherlands",
    title: "AI and the political party",
    date: "2018 December",
  },
  {
    conferenceName: "CZI Science Computational Biology RFA Gathering",
    conferenceUrl: null,
    videoUrl: null,
    deckUrl: null,
    city: "Aptos, California, USA",
    title: "A Modern Toolkit for Scientific Data Visualization",
    date: "2018 April",
  },
  {
    conferenceName: "Foo Camp 2017",
    conferenceUrl: null,
    videoUrl: null,
    deckUrl: null,
    city: "San Francisco, USA",
    title: "AI and the political party",
    date: "2017 October",
  },
  {
    conferenceName: "PDF 2017",
    conferenceUrl: "https://personaldemocracy.com/conference",
    videoUrl: "https://www.youtube.com/watch?v=SlZfyEQS0s8",
    deckUrl: null,
    city: "NYC, USA",
    title: "Stories From the Future of Democracy: The Promise of AI",
    date: "2017 June",
  },
  {
    conferenceName: "Ed Foo",
    conferenceUrl: null,
    videoUrl: null,
    deckUrl: null,
    city: "Mountain View, USA",
    title: null,
    date: "2017 March",
  },
  {
    conferenceName: "Internews",
    conferenceUrl: null,
    videoUrl: null,
    deckUrl: null,
    city: "Washington D.C., USA",
    title: "An introduction to pol.is",
    date: "2017 January",
  },
  {
    conferenceName: "Collective Intelligence for Democracy",
    conferenceUrl:
      "http://medialab-prado.es/article/madrid-inteligencia-colectiva-para-la-democracia",
    videoUrl: "https://www.youtube.com/watch?v=ofhjnrgJLAc",
    deckUrl: null,
    city: "Madrid, Spain",
    title: "AI & the future of participatory democracy",
    date: "2016 November",
  },
  {
    conferenceName: "School of Civic Tech",
    conferenceUrl: "http://www.tecnologieciviche.eu/",
    videoUrl: null,
    deckUrl: null,
    city: "Naples, Italy",
    title: "AI & the future of participatory democracy",
    date: "2016 November",
  },
  {
    conferenceName: "School of Civic Tech",
    conferenceUrl: "http://www.tecnologieciviche.eu/",
    videoUrl: null,
    deckUrl: null,
    city: "Turin, Italy",
    title: "AI & the future of participatory democracy",
    date: "2016 November",
  },
  {
    conferenceName: "Newspeak House",
    conferenceUrl:
      "http://www.cybersalon.org/give-people-the-vote-and-they-will-come-18th-october-2016/",
    videoUrl:
      "http://www.cybersalon.org/give-people-the-vote-and-they-will-come-18th-october-2016/",
    deckUrl: null,
    city: "London, UK (remotely)",
    title: "A brief introduction to pol.is",
    date: "2016 October",
  },
  {
    conferenceName: "Next:Economy",
    conferenceUrl: "https://conferences.oreilly.com/nextcon/economy-us",
    videoUrl: "https://www.youtube.com/watch?v=09Lqj5lazKM",
    deckUrl: null,
    city: "San Francisco, USA",
    title: "A Conversation with Colin Megill of pol.is",
    date: "2016 October",
  },
  {
    conferenceName: "Foo Camp 2016",
    conferenceUrl: null,
    videoUrl: null,
    deckUrl: null,
    city: "Sebastopol, USA",
    title: null,
    date: "2016 June",
  },
  {
    conferenceName: "g0v 2016",
    conferenceUrl: "http://summit.g0v.tw/2016/speakers",
    videoUrl: "https://blog.pol.is/pol-is-in-taiwan-da7570d372b5",
    deckUrl: null,
    city: "Taipei, Taiwan",
    title:
      "pol.is in Taiwan: Bridging the gap between public discourse & public policy",
    date: "2016 May",
  },
  {
    conferenceName: "By the Crowd",
    conferenceUrl: "http://info61430.wixsite.com/campbythecrowd_t",
    videoUrl: null,
    deckUrl: null,
    city: "Jeju Island, Korea",
    title: "New methods in participatory democracy",
    date: "2015 December",
  },
  {
    conferenceName: "Future of Web Apps",
    conferenceUrl: null,
    videoUrl: null,
    deckUrl: "http://fowa2015.surge.sh/#/title",
    city: "Boston, USA",
    title: "Victory.js, A powerful data visualization library for ReactJS",
    date: "2015 November",
  },
  {
    conferenceName: "Reactive2015",
    conferenceUrl: "https://reactive2015.com/schedule_speakers.html#team",
    videoUrl: "https://www.youtube.com/watch?v=n8TwLWsR40Y&t=52s",
    deckUrl: null,
    city: "Bratislava, Slovakia",
    title: "Victory.js, A powerful data visualization library for ReactJS",
    date: "2015 November",
  },
  {
    conferenceName: "Shop Talk Show",
    conferenceUrl: "https://shoptalkshow.com/",
    videoUrl: "https://shoptalkshow.com/episodes/180-panel-on-inline-styles/",
    deckUrl: null,
    city: "Interwebs, Podcast",
    title: "Panel discussion on inline styles",
    date: "2015 August",
  },
  {
    conferenceName: "QCon",
    conferenceUrl: "http://lanyrd.com/2015/qcon-new-york/",
    videoUrl: null,
    deckUrl: null,
    city: "NYC, USA",
    title: "Data visualization in ReactJS",
    date: "2015 June",
  },
  {
    conferenceName: "CSS Conf 2015",
    conferenceUrl: "https://2015.cssconf.com/",
    videoUrl: "https://www.youtube.com/watch?v=NoaxsCi13yQ&t=760s",
    deckUrl: null,
    city: "NYC, USA",
    title: "Inline styles are about to kill CSS",
    date: "2015 June",
  },
  {
    conferenceName: "Future of Web Apps",
    conferenceUrl: null,
    videoUrl: null,
    deckUrl: null,
    city: "Las Vegas, USA",
    title: "RadiumJS & VictoryJS: Data Visualization in ReactJS",
    date: "2015 June",
  },
  {
    conferenceName: "SeattleJS @ Facebook",
    conferenceUrl: "",
    videoUrl: "https://www.youtube.com/watch?v=6fhTawDEE9k",
    deckUrl: null,
    city: "Seattle, USA",
    title: "Learn React and Flux: Part III",
    date: "2015 April",
  },
  {
    conferenceName: "SeattleJS @ Facebook",
    conferenceUrl: "",
    videoUrl: "https://www.youtube.com/watch?v=iR22EWW-CVc",
    deckUrl: null,
    city: "Seattle, USA",
    title: "Learn React and Flux: Part II",
    date: "2015 March",
  },
  {
    conferenceName: "SeattleJS @ Facebook",
    conferenceUrl: "",
    videoUrl: "https://www.youtube.com/watch?v=Pd6Ub7Ju2RM",
    deckUrl: null,
    city: "Seattle, USA",
    title: "Learn React and Flux: Part I",
    date: "2015 February",
  },
]

export default talks
